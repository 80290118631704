import React from 'react';
import styled from 'styled-components';
import MediaQueries from '../utils/media-queries';
import { Link } from 'gatsby';

const Nav = styled.nav`
  font-family: 'Catamaran', sans-serif;
  order: 1;
  align-self: flex-end;
  margin: 0;
  padding: ${(props) => (props.path === '/' ? '4vh 5vw' : '0.5rem')};
  ${(props) => (props.path !== '/' ? 'color: inherit;' : null)};

  @media only screen and (max-width: ${MediaQueries.insect}) {
    order: ${(props) => (props.path !== '/' ? 1 : 2)};
    width: 100%;
    font-size: 0.9rem;
    transition: background-color 0.3s;
    background-color: rgba(255, 215, 0, 1); //gold
    align-self: center;
  }
`;

const NavigationList = styled.ul`
  margin: 0;
  display: flex;
  justify-content: flex-end;
  li {
    margin: 0 1rem 0 0;
    padding 0 1rem 0 0;
    list-style: none;
    display: block;
    position: relative;
    border-right: 1px solid #333;
    transition: 0.3s;
    :last-child {
      margin: 0;
      border-right: none;
    }
    a {
      color: inherit;
      padding: 0.5rem;
      text-decoration: none;
    }
  }
  :hover > li {
    opacity: 0.5;
    border-right: none;
  }
  :hover > li:hover {
    opacity: 1;
    transform: scale(1.2);
    a {
      text-decoration: underline;
      text-decoration-color: gold;
    }
  }

  @media only screen and (max-width: ${MediaQueries.teeny}) {
    display: block;
    text-align: right;
    li {
      border: none;
      margin: 0 0.2rem;
      padding: 0;
      :last-child {
        margin: 0 0.2rem;
      }
    }
    :hover > li {
      opacity: 0.5;
    }
    :hover > li:hover {
      opacity: 1;
      transform: scale(1);
      a {
        text-decoration: underline;
        text-decoration-color: gold;
      }
  }
`;

export default () => {
  return (
    <Nav>
      <NavigationList>
        <li>
          <Link to='/about'>about</Link>
        </li>
        <li>
          <Link to='/portfolio'>projects</Link>
        </li>
        {/* <li>
          <Link to="/#blog">blog</Link>
        </li> */}
      </NavigationList>
    </Nav>
  );
};
