import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const Footer = styled.footer`
  font-family: 'Catamaran', -apple-system, BlinkMacSystemFont, Segoe UI, 'Roboto', Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  color: white;
  background-color: #333;
  grid-area: Footer;
  margin-top: 5rem;
  padding: 3vh 2vw 6vh;
`;

const BuiltWith = styled.ul`
  list-style: none;
  display: flex;
  margin: 0 auto 1rem;
  padding: 0;
  width: max-content;
  li {
    margin: 0 0 0;
    padding: 0;
  }
  a {
    padding: 0.5rem;
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    //width: 100%;
  }

  a img {
    position: relative;
    display: block;
    //height: 50px;
    //width: 50px;
    margin: 0;
    background-color: transparent;
  }
  a span {
    margin-left: 0.5rem;
  }
`;

const Social = styled.section`
  --socialHeight: 20px;
  margin: 0 0 calc(1.666rem / 2);
  padding: 0;
  //text-align: right;
  display: flex;
  justify-content: flex-end;

  height: var(--socialHeight);
  img {
    position: relative;
    height: var(--socialHeight);
    width: var(--socialHeight);
    margin: 0 0.5rem;
    padding: 0;
  }
`;

const FooterLinks = styled.nav`
  ul {
    margin: 1rem auto 0;
    padding: 0;
    color: white;
    display: flex;
    flex-direction: row-reverse;
    list-style: none;
    align: right;
    li {
      margin-left: 1rem;
      color: inherit;
      a {
        color: inherit;
        font-size: 0.9rem;
      }
    }
  }
`;

const Copyright = styled.p`
  text-align: right;
  margin: 0;
  padding: 0;
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      GatsbyIcon: file(relativePath: { eq: "gatsbyicon.png" }) {
        childImageSharp {
          fixed(width: 20, height: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ReactIcon: file(relativePath: { eq: "reacticon.png" }) {
        childImageSharp {
          fixed(width: 20, height: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      NetlifyIcon: file(relativePath: { eq: "netlifyicon.png" }) {
        childImageSharp {
          fixed(width: 20, height: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Footer>
      <p style={{ textAlign: 'center' }}>
        <small>joshlong.design is proudly built using</small>
      </p>
      <BuiltWith>
        <li>
          <a rel='noopener noreferer' href='https://www.gatsbyjs.org'>
            <Img title='GatsbyJS' fixed={data.GatsbyIcon.childImageSharp.fixed} alt='GatsbyJS' />{' '}
            <span>GatsbyJS</span>
          </a>
        </li>
        <li>
          <a rel='noopener noreferer' href='https://www.gatsbyjs.org'>
            <Img title='ReactJS' fixed={data.ReactIcon.childImageSharp.fixed} alt='ReactJS' />{' '}
            <span>ReactJS</span>
          </a>
        </li>
        <li>
          <a rel='noopener noreferer' href='https://www.gatsbyjs.org'>
            <Img
              title='NetlifyCMS'
              fixed={data.NetlifyIcon.childImageSharp.fixed}
              alt='NetlifyCMS'
            />{' '}
            <span>NetlifyCMS</span>
          </a>
        </li>
      </BuiltWith>

      <FooterLinks>
        <ul>
          <li>
            <Link to='/cookie-policy'>Cookie Policy</Link>
          </li>
          <li>
            <Link to='/privacy'>Privacy Policy</Link>
          </li>
          <li>
            <Link to='/portfolio'>Portfolio</Link>
          </li>
        </ul>
      </FooterLinks>
      <Social>
        <a href='https://www.facebook.com/JLDesignBranding'>
          <img title='' src='/assets/facebook.png' alt='' />
        </a>
        <a href='https://www.linkedin.com/in/joshlongdesigner/'>
          <img title='' src='/assets/linkedin.png' alt='' />
        </a>
      </Social>
      <Copyright>&#169; 2019 - {new Date().getFullYear()} Josh Long</Copyright>
    </Footer>
  );
};
