import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { createGlobalStyle } from 'styled-components';
import Header from './Header';
import MediaQueries from '../utils/media-queries';
import { Location } from '@reach/router';
import Footer from './Footer';

require('typeface-catamaran');

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }  
  body {
    overflow-x: hidden;
  }
  h4 {
    margin-left: 1rem;
  }
  blockquote {
    font-size: 0.9rem;
    padding: 0.2rem 1.5rem;
    margin: 2rem;
    background-color: #fff;
    color: #666;
    border-left: 4px solid rgba(255, 215, 0, 0.3);
    :before {
      content: '“';
      font-family: sans-serif;
      text-align: left;
      display: block;
      position: relative;
      font-size: 4rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 1rem;
      color: gold;
      left: -1rem;
    }
    :after {
      content: '”';
      font-family: sans-serif;
      text-align: right;
      display: block;
      position: relative;
      font-size: 4rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 1rem;
      color: gold;
      right: -1rem;
      bottom: -1.5rem;
    }
  }
`;

const LayoutContainer = styled.div`
  display: grid;
  grid-template-areas:
    'Header Header Header'
    '. Content .'
    'Footer Footer Footer';
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1rem 1fr 1rem;
  padding: 0 2vw;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 1200px;
  //box-shadow: 0 0 30px -15px rgba(0, 0, 0, 0.6);

  @media only screen and (max-width: ${MediaQueries.hooman}) {
    //background-color: gray;
  }

  @media only screen and (max-width: ${MediaQueries.fish}) {
    //background-color: lightblue;
    grid-template-columns: 0 1fr 0;
    padding: 0;
  }

  @media only screen and (max-width: ${MediaQueries.insect}) {
    grid-template-areas:
      'Header'
      'Content'
      'Footer';
    grid-template-columns: 1fr;
    padding: 0;
  }
`;

const ContentContainer = styled.main`
  grid-area: Content;
`;

export default ({ children }) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }} />
      <GlobalStyle />
      <LayoutContainer>
        <Location>
          {({ location }) => {
            return <Header path={location.pathname} search={location.search} />;
          }}
        </Location>
        <ContentContainer>{children}</ContentContainer>
        <Footer />
      </LayoutContainer>
    </>
  );
};
