import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Navigation from './Navigation';
import MediaQueries from '../utils/media-queries';

const HeaderContainer = styled.header`
  grid-area: Header;
  background: white;
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.path === '/' ? '5vh 5vw 5vh' : '0 5vw 0')};
  margin: 0 0 2rem;
  border-bottom: ${(props) => (props.path !== '/' ? '2px solid #333' : 'none')};
  @media only screen and (max-width: ${MediaQueries.insect}) {
    padding: 0;
    ${(props) =>
      props.path !== '/'
        ? 'flex-direction: row; transition: background-color 0.5s; background-color: rgba(255, 215, 0, 1); align-items: center;'
        : null}
  }

  @media only screen and (max-width: ${MediaQueries.nano}) {
    padding: 0;
  }
`;

const SiteTitle = styled.h1`
  order: 2;
  margin: ${(props) => (props.path === '/' ? '10vh 0 0 0' : '0.5rem 0 0.5rem 0')};
  transition: 0.3s;
  a {
    color: inherit;
    :hover {
      text-decoration-color: gold;
    }
  }

  @media only screen and (min-width: ${MediaQueries.fish}) {
    font-size: ${(props) => (props.path === '/' ? 'calc(2vw + 2rem)' : '2rem')};
  }
  @media only screen and (max-width: ${MediaQueries.insect}) {
    padding: 0 0.5rem;
    margin: ${(props) => (props.path === '/' ? '1.5rem 0' : null)};
    order: ${(props) => (props.path === '/' ? 2 : 1)};
    transition: none;
  }
`;

export default (props) => {
  return (
    <HeaderContainer path={props.path}>
      <SiteTitle path={props.path}>
        <Link to='/'>Josh</Link>
      </SiteTitle>
      {props.path === '/' ? (
        <div style={{ order: 3 }}>
          <p style={{ marginBottom: 0, padding: '0 0.5rem' }}>
            <i>noun / &#100;&#658;&#594;&#643; /</i>
          </p>
          <p
            style={{
              fontSize: '0.9rem',
              fontWeight: '500',
              padding: ' 0 0.5rem',
            }}
          >
            User-centred real world content designer and brand developer.
          </p>
        </div>
      ) : null}
      <Navigation path={props.path} />
    </HeaderContainer>
  );
};
