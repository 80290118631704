import React from 'react';
import { Helmet } from 'react-helmet';

export default ({ type = null, title = null, description = null, image = null }) => {
  const CreativeWorkSchema = () => {
    return (
      <script type='application/ld+json'>{`
        {
            "@context": "http://schema.org",
            "@type" : "${type}",
            "name" : "${title}",
            "headline" : "${description}",
            "image" : "${image}",
            "description" : "${description}"
        }
      `}</script>
    );
  };
  const WebPageSchema = () => {
    return (
      <script type='application/ld+json'>{`
        {
            "@context": "http://schema.org",
            "@type" : "${type}",
            "name" : "${title}",
            "image" : "${image}",
            "description" : "${description}"
        }
      `}</script>
    );
  };
  return <Helmet>{type === 'Article' ? CreativeWorkSchema() : WebPageSchema()}</Helmet>;
};
